body {
  margin: 0;
  font-family: 'Apercu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga', 'kern';
  font-variant-numeric: proportional-nums;
}

html {
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@page {
  size: auto;
  margin: 8mm;
}
